.container {
    height: 100vh;
    padding: 4vh 5vw;
    width: 100%;
}
.innerContainer {
    height: 100%;
    display: flex;
    align-items: center;
}
.instructionsDiv {
    padding: 3%;
    /* width: 80%; */
    height: 40vh;
    box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);
    overflow-y: scroll;
    overflow-x: auto;
}

.resumeParsingFailed{
    width: 65vw; 
    height: 48vh; 
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .resumeParsingFailed{
        width: 90vw; 
    }
}