.loaderCircle{
    background-color: rgb(26,115,232);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.loader{
    position: relative;
    top: 4px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5px;
    width:40px;
}

.loader .stroke{
    display: block;
    position: relative;
    background-color: #fff;
    height: 20%;
    width: 4px;
    border-radius: 50px;
    /* margin: 1.5px; */
}