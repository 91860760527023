.container {
    height: 100vh;
    padding: 4vh 5vw;
    width: 100%;
}

.innerContainer {
    height: 100%;
    display: flex;
    align-items: center;
}

.recorder {
    display: none;
}

.questionContainer {
    position: relative;
    font-size: 1.8rem;
    /* border:2px solid red; */
    height: 28vh;
}

.voice {
    position: relative;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* visibility: hidden; */
}

.interview_timer {
    margin-top: 10px;
    font-size: 2rem;
    color: #252222;
    /* border: 2px solid red; */
}

.question_button {
    position: relative;
    top: 20vh;
    display: flex;
    justify-content: center;
    width: 100%;
}

.interview_getting_ready {
    /* margin: 33% 0px; */
}

.interview_complete_div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
}

.interview_complete_text {
    /* vertical-align: middle; */
    text-align: center;
    width: 100%;
}

.webcam_container {
    position: absolute;
    /* width: 5rem; */
    bottom: 0px;
    right: 0px;
    padding: 2rem;
}

.webcam_container video {
    border-radius: 6px;
    overflow: hidden;
    border: 3px solid rgb(26, 115, 232);
    box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);
}

.animationOverVideo {
    position: relative;
    /* border: 2px solid red; */
    top: 2.2rem;
    /* left: 17rem; */
    left: 85%;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    /* background-color: #2e3030; */
    /* background-color: #161C24; */
    background-color: rgb(26, 115, 232);
    padding: 0px;
}

.insideAnimationOverVideo {
    position: relative;
    top: -5px;
    left: -5px;
}

.animationOverVideo svg {
    z-index: 1;
    position: relative;
    top: 20px;
}

.instructionsDiv {
    padding: 3%;
    width: 80%;
    height: 40vh;
    box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);
}

@media screen and (max-width: 1100px) {
    .instructionsDiv {
        width: 100%;
        overflow-y: scroll;
        overflow-x: auto;
    }
}

@media screen and (max-width: 800px) {
    /* @media screen and (max-height: 6000px) {
        .webcam_container {
            bottom: 18%;
        }
    }

    @media screen and (max-height: 2000px) {
        .webcam_container {
            bottom: 15%;
        }
    }

    @media screen and (max-height: 1000px) {
        .webcam_container {
            bottom: 12%;
        }
    } */
    .webcam_container {
        bottom: 8vh;
    }

    .webcam_container {
        padding: 1rem;
    }

    .animationOverVideo {
        left: 94%;
    }

}

@media screen and (max-width: 450px) {
    .questionContainer {
        font-size: 1.5rem;
    }

    .animationOverVideo {
        left: 90%;
    }

}